<template>
    <div>
        <Search :cur="cur" />
        <div class="tags">
            <div>
                <span :class="cur == 0 ? 'active' : ''" @click="cur = 0">需求</span>
                <span :class="cur == 1 ? 'active' : ''" @click="cur = 1">店铺</span>
            </div>
        </div>
        <!-- trigger="click" -->
        <el-carousel height="500px">
            <el-carousel-item v-for="item in balnner" :key="item.id">
                <div class="swiper">
                    <img :src="$util.host + item.url" @click="toHerf(item)" alt="" />
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="mian">
            <div class="mian-box">
                <div class="menus">
                    <div :class="index == clssListIndex ? 'menus-active' : ''" v-for="(item, index) in clssList.slice(0, 5)" :key="item.id" @click="changeIndex(item, index)">
                        <img style="width: 54px; height: 50px; margin-bottom: 25px" :src="$util.host + item.thumbnail" alt="" />
                        {{ item.name }}
                    </div>

                    <div v-if="clssList.length > 5">
                        <el-dropdown placement="bottom" trigger="click" @command="indexChange">
                            <img style="width: 35px; height: 35px" src="../../assets/images/serve/gd.png" alt="" />
                            <span class="el-dropdown-link"> 更多 </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in clssList.slice(5, 100)" :key="item.id">{{ item.name }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="需求" name="first">
                        <div class="zan">
                            <p>
                                <img src="../../assets/images/serve/zan.png" alt="" />
                                优质需求
                            </p>
                        </div>
                        <div class="goodList">
                            <div class="litle-cell" v-for="item in goodNeedList" :key="item.id" @click="goDemand(item,item.id)">
                                <div class="litle-title">{{ item.name }}</div>
                                <div class="tag-box">
                                    <div class="tag" v-text="item.sendUserType == 1 ? '个人用户' : '企业用户'"></div>
                                    <div class="tag">{{ industry[item.sendIndustry] }}</div>
                                    <div class="tag">
                                        {{ mapAll[item.sendProvince] }}{{ mapAll[item.sendCity] }}
                                    </div>
                                </div>
                                <div class="litle-money">预算 ￥ {{ item.sendMoneyBudget }}</div>
                            </div>
                        </div>
                        <!-- <div class="need">
                            <div class="need-cell" v-for="item in goodNeed.slice(0,4)" :key="item.id" @click="goDemand(item.id)">
                                <div class="title">{{item.name}}</div>
                                <div class="tag-box">
                                    <div class="tag" v-text="item.sendUserType == 1?'个人用户':'企业用户'"></div>
                                    <div class="tag">{{industry[item.sendIndustry]}}</div>
                                    <div class="tag">{{mapAll[item.sendProvince]}}{{mapAll[item.sendCity]}}</div>
                                </div>
                                <div class="need-info">
                                    <div class="need-logo">
                                        <img :src="$util.host +item.sendUserHeadPic" alt="">
                                        {{item.sendUserName}}
                                    </div>
                                    <div>预算 ￥{{item.sendMoneyBudget}}</div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="litle-need">
                    <div
                      class="litle-cell"
                      v-for="item in goodNeed.slice(5, 20)"
                      :key="item.id"
                    >
                      <div class="litle-title">{{ item.name }}</div>
                      <div class="tag-box">
                        <div
                          class="tag"
                          v-text="item.sendUserType == 1 ? '个人用户' : '企业用户'"
                        ></div>
                        <div class="tag">{{ industry[item.sendIndustry] }}</div>
                        <div class="tag">
                          {{ mapAll[item.sendProvince] }}{{ mapAll[item.sendCity] }}
                        </div>
                      </div>
                      <div>预算 ￥{{ item.sendMoneyBudget }}</div>
                    </div>
                  </div> -->
                    </el-tab-pane>
                    <el-tab-pane label="店铺" name="second">
                        <div class="zan">
                            <img src="../../assets/images/serve/zan.png" alt="" />
                            优质店铺
                        </div>
                        <div class="nice-shop goodShop">
                            <div class="shop" v-for="item in goodShopList" :key="item.id" @click="goShop(item.id)">
                                <div class="shop-info">
                                    <div class="shopName">{{ item.name }}</div>
                                    <div class="shop-img">
                                        <img :src="$util.host + item.headPic" alt="" />
                                        <div v-if="item.type == 2">企</div>
                                    </div>
                                    <!-- 类型 -->
                                    <div class="classTab">
                                        <!-- [0].littleItems -->
                                        <span v-for="val in item.shopClassify" :key="val.id">{{ val.name }}|</span>
                                    </div>
                                    <div class="shop-tags">
                                        <div v-show="item.shopClassify.length > 1">多类</div>
                                        <div v-show="item.shopClassify.length == 1">
                                            {{ item.shopClassify[0].name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="shop-num">
                                    <div>服务雇主{{ item.workForUserSize }}家</div>
                                    月成交量{{ item.mouthOverSize }}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-if="current == 0">
                <!-- 分类 -->
                <div class="classification">
                    <div class="slece">
                        <div class="areas">
                            <div @click="show = !show">
                                <span>地区</span> <i class="el-icon-arrow-down"></i>
                            </div>
                            <div class="down" v-show="show">
                                <div :class="provinceIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in provinceList" :key="item.id" @click="changeCityIndex(item, index)">
                                    <div>
                                        <span>{{ item.province }}</span> <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="downt" v-show="show">
                                <div v-if="provinceList[provinceIndex]">
                                    <div :class="cityIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in provinceList[provinceIndex].cities" :key="item.id" @click="getCityId(item, index)">
                                        <div>
                                            <span>{{ item.city }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slece">
                        <el-dropdown trigger="click" placement="bottom" @command="getTime">
                            <span class="el-dropdown-link">
                                发布时间<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in releaseTime" :key="item.id">{{ item.title }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="slece">
                        <el-dropdown trigger="click" placement="bottom" @command="getIndustry">
                            <span class="el-dropdown-link">
                                行业<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in industryList" :key="item.id">{{ item.title }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="slece">
                        <el-dropdown trigger="click" placement="bottom" @command="getType">
                            <span class="el-dropdown-link">
                                发布者类别<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in publisherType" :key="item.id">{{ item.title }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 分类内容 -->
                <div class="classification-content">
                    <div class="litle-need">
                        <div class="litle-cell" v-for="item in needList" :key="item.id" @click="goDemand(item,item.id)">
                            <div class="litle-title">{{ item.name }}</div>
                            <div class="tag-box">
                                <div class="tag" v-text="item.sendUserType == 1 ? '个人用户' : '企业用户'"></div>
                                <div class="tag">{{ industry[item.sendIndustry] }}</div>
                                <div class="tag">
                                    {{ mapAll[item.sendProvince] }}{{ mapAll[item.sendCity] }}
                                </div>
                            </div>
                            <div class="litle-money">预算 ￥ {{ item.sendMoneyBudget }}</div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="needPage.pageSize" :current-page="needPage.pageNo" @current-change="changeNeed" :total="needPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{ needPage.totalRows }}件/{{ needPage.totalPage }}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="needNum" />
                        页
                    </div>
                    <el-button style="
              background: #2ea7e0;
              color: #fff;
              width: 108px;
              height: 50px;
              font-size: 20px;
            " @click="goPage">前往</el-button>
                </div>
            </div>
            <div v-if="current == 1">
                <!-- 分类 -->
                <div class="classification">
                    <div class="slece">
                        <div class="areas">
                            <div @click="shopShow = !shopShow">
                                <span>地区</span> <i class="el-icon-arrow-down"></i>
                            </div>
                            <div class="down" v-show="shopShow">
                                <div :class="shopProIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in shopProList" :key="item.id" @click="changeShopCityIndex(item, index)">
                                    <div>
                                        <span>{{ item.province }}</span> <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="downt" v-show="shopShow">
                                <div v-if="shopProList[shopProIndex]">
                                    <div :class="shopCityIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in shopProList[shopProIndex].cities" :key="item.id" @click="getShopCityId(item, index)">
                                        <div>
                                            <span>{{ item.city }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slece">
                        <el-dropdown trigger="click" placement="bottom" @command="getShopTime">
                            <span class="el-dropdown-link">
                                开店时长<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">{{ item.title }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="slece">
                        <el-dropdown trigger="click" placement="bottom" @command="shopType">
                            <span class="el-dropdown-link">
                                店铺类别<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item" v-for="item in publisherType" :key="item.id">{{ item.title }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 分类内容 -->
                <div class="classification-content">
                    <div class="nice-shop">
                        <div class="shop" v-for="item in goodShopDefList" :key="item.id" @click="goShop(item.id)">
                            <div class="shop-info">
                                <div class="shopName">{{ item.name }}</div>
                                <div class="shop-img">
                                    <img :src="$util.host + item.headPic" alt="" />
                                    <div v-if="item.type == 2">企</div>
                                </div>
                                <div class="classTab">
                                    <!-- [0].littleItems -->
                                    <span v-for="(val,index) in item.shopClassify" :key="val.id"><span v-if="index!==0">|</span>{{ val.name }}</span>
                                </div>
                                <div class="shop-tags">
                                    <div v-show="item.shopClassify.length > 1">多类</div>
                                    <div v-show="item.shopClassify.length == 1">
                                        {{ item.shopClassify[0].name }}
                                    </div>
                                </div>
                            </div>
                            <div class="shop-num">
                                <div>服务雇主{{ item.workForUserSize }}家</div>
                                月成交量{{ item.mouthOverSize }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize" :current-page="shopPage.pageNo"  @current-change="changePage" :total="shopPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{ shopPage.totalRows }}件/{{ shopPage.totalPage }}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="shopNum" />
                        页
                    </div>
                    <el-button style="
              background: #2ea7e0;
              color: #fff;
              width: 108px;
              height: 50px;
              font-size: 20px;
            " @click="goShopPage">前往</el-button>
                </div>
            </div>
        </div>
        <!-- 客服 -->
        <!-- <div class="chat" @click="">
            <div class="top">
                <span>在线咨询</span>
                <img src="../../assets/images/lg.png" alt="">
            </div>
            <div class="bottom">ONLINE CONSULTING</div>
        </div>
        <div class="dialogue">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="在线咨询" name="1">
                    <div style="width:200px;height:300px"></div>
                    <div></div>
                </el-collapse-item>
            </el-collapse>
        </div> -->
        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import Search from "../../components/search.vue";
export default {
    components: { Footer, Search },
    name: "QppIndex",
	metaInfo: {
		title: '专利转让_商标转让_ISO资质体系认证等企业服务就到企胖胖', // set a title
		meta: [{
				name: 'description',
				content: '企胖胖网是一家以企业服务为核心，提供个人，企业免费需求发布，免费接单，平台提供在线交易托管服务保障资金安全,助力为中小微企业提供专业企业服务支持；'
			},
			{
				name: 'keyWords',
				content: '企胖胖，注册公司，代理记账，知识产权，商标转让，LOGO设计，软件著作申请，软件开发，ISO体系认证'
			},
		]
	},
    data() {
        return {
            cur: 1, //当前下标
            current: 0, //当前下标
            activeName: "first",
            clssListIndex: 0, //分类的当前下标
            classifyId: 0, //分类ID sessionStorage.getItem("classifyId") || 
            balnner: [], //轮播图
            clssList: [], //分类数组
            goodNeed: [], //优质需求数组
            needList: [], //需求数组
            goodNeedList: [],
            provinceList: [], //省
            provinceIndex: 0, //省下标
            cityIndex: 0, //市下标
            show: false,
            releaseTime: [
                { id: 0, title: "3天内", day: 3 },
                { id: 1, title: "一周内", day: 7 },
                { id: 2, title: "一个月内", day: 30 },
                { id: 3, title: "3个月内", day: 90 },
                { id: 4, title: "半年内", day: 180 },
                { id: 5, title: "1年内", day: 365 },
                { id: 6, title: "3年内", day: 1095 },
            ], //发布时间
            industryList: [
                { id: "A", title: "农、林、牧、渔业" },
                { id: "B", title: "采矿业," },
                { id: "C", title: "制造业" },
                { id: "D", title: "电力、热力、燃气及水生产和供应业" },
                { id: "E", title: "建筑业" },
                { id: "F", title: "批发和零售业" },
                { id: "G", title: "交通运输、仓储和邮政业" },
                { id: "H", title: "住宿和餐饮业" },
                { id: "I", title: "信息传输、软件和信息技术服务业" },
                { id: "J", title: "金融业" },
                { id: "K", title: "房地产业" },
                { id: "L", title: "租赁和商务服务业" },
                { id: "M", title: "科学研究和技术服务业" },
                { id: "N", title: "水利、环境和公共设施管理业" },
                { id: "O", title: "居民服务、修理和其他服务业" },
                { id: "P", title: "教育" },
                { id: "Q", title: "卫生和社会工作" },
                { id: "R", title: "文化、体育和娱乐业" },
                { id: "S", title: "公共管理、社会保障和社会组织" },
                { id: "T", title: "国际组织" },
            ], //行业
            industry: {
                A: "农、林、牧、渔业",
                B: "采矿业,",
                C: "制造业",
                D: "电力、热力、燃气及水生产和供应业",
                E: "建筑业",
                F: "批发和零售业",
                G: "交通运输、仓储和邮政业",
                H: "住宿和餐饮业",
                I: "信息传输、软件和信息技术服务业",
                J: "金融业",
                K: "房地产业",
                L: "租赁和商务服务业",
                M: "科学研究和技术服务业",
                N: "水利、环境和公共设施管理业",
                O: "居民服务、修理和其他服务业",
                P: "教育",
                Q: "卫生和社会工作",
                R: "文化、体育和娱乐业",
                S: "公共管理、社会保障和社会组织",
                T: "国际组织",
            }, //行业.
            mapAll: {},
            publisherType: [
                { id: 2, title: "企业用户" },
                { id: 1, title: "个人用户" },
            ], // 发布者类型
            demandScreening: {
                //需求筛选
                sendProvince: "", //省
                sendCity: "", //市
                day: 0, //时间
                sendIndustry: "", //行业
                userType: 0, //类型
                classifyId: 0, //分类id sessionStorage.getItem("classifyId") || 
                pageNo: 1,
                pageSize: 24,
            },
            needNum: "", //需求分页的指定页数
            needPage: {}, //需求总页数
            goodShopList: [], //所有店铺的列表
            goodShopDefList: [], //所有需求列表
            shopShow: false, //店铺显示
            shopProList: [], //省
            shopProIndex: 0, //店铺城市下标
            shopCityIndex: 0, //店铺城市下标
            shopTime: [
                //时间筛选
                { id: 0, title: "大于3年", day: 3 },
                { id: 1, title: "大于2年", day: 2 },
                { id: 2, title: "大于1年", day: 1 },
                { id: 3, title: "小与1年", day: 0 },
            ],
            shopSelect: {
                //店铺的筛选参数
                addr: "",
                type: 0,
                moreYear: 0,
                lessYear: 0,
                pageNo: 1,
                classifyId: 0, //分类id
                pageSize: 24,
                // totalRows: 12
            },
            shopNum: "", //店铺的页数
            shopPage: {}, //需求总页数
        };
    },
    mounted() {
        this.allNeedData();
        this.getProvince();
        this.allShopData();
        this.getAllMap();
        this.filterGoodNeed();
        this.clssListIndex = sessionStorage.getItem("clssListIndex")
        console.log(this.classifyId);

    },
    beforeCreate() {
        console.log('我刷新了')
    },
    methods: {
        //打开新页面
        toHerf(val) {
            if (val.href !== null) {
                window.open(val.href, '_blank')
            }


        },
        // 获取所有地址
        getAllMap() {
            this.$util.post("/address/allMap").then((res) => {
                this.mapAll = res.data.all;
            });
        },

        handleClick(tab) {
            this.current = tab.index;
        },
        // 分类改变触发
        indexChange(item) {
            this.clssListIndex = 5;
            this.classifyId = item.id;
            (this.demandScreening = {
                //需求筛选
                sendProvince: "", //省
                sendCity: "", //市
                day: 0, //时间
                sendIndustry: "", //行业
                userType: 0, //类型
                classifyId: "", //分类id
                pageNo: 1,
                pageSize: 24,
            }),
                (this.demandScreening.classifyId = item.id);
            this.shopSelect = {
                addr: "",
                type: 0,
                moreYear: 0,
                lessYear: 0,
                pageNo: 1,
                classifyId: "", //分类id
                pageSize: 24,
            };
            this.shopSelect.classifyId = item.id;
            this.filterNeed();
            this.getShopList();
            this.getBalnner();
            this.allNeedData();
            this.filterGoodNeed();
        },
        // 获取轮播图
        getBalnner() {
            // if (sessionStorage.getItem("classifyId") == null) {
                console.log(111);

                this.$util
                    .post("/home/banner", {
                        type: "1",
                        classifyId: "1",
                    })
                    .then((res) => {
                        console.log(res);
                        this.balnner = res.data;
                    });
            // } else {
            //     console.log(this.classifyId);

            //     this.$util
            //         .post("/home/banner", {
            //             type: "1",
            //             classifyId: this.classifyId,
            //         })
            //         .then((res) => {
            //             console.log(res);
            //             this.balnner = res.data;
            //         });
            // }

        },
        // 需求数据
        allNeedData() {
            console.log(this.classifyId);

            this.$util
                .post("/demand/index", {
                    classifyId: this.classifyId,
                })
                .then((res) => {
                    this.clssList = res.data.classifyBaseList;
                    // this.demandScreening.classifyId = res.data.classifyBaseList[0].id
                    // this.classifyId = res.data.classifyBaseList[0].id
                    // this.shopSelect.classifyId = res.data.classifyBaseList[0].id
                    this.goodNeed = res.data.recommendList;
                    this.needList = res.data.defList.data;
                    this.needPage = res.data.defList.pager;
                    this.filterNeed();
                    this.getShopList();
                    this.getBalnner();
                    this.filterGoodNeed();
                    this.allShopData()
                });
        },
        // 获取省
        getProvince() {
            this.$util.post("/address/all").then((res) => {
                for (let key in res.data) {
                    this.provinceList.push(res.data[key]);
                    this.shopProList.push(res.data[key]);
                }
            });
        },
        // 需求筛选
        filterNeed() {
            this.$util.post("/demand/list", this.demandScreening).then((res) => {
                console.log(res);
                this.needPage = res.pager;
                this.needList = res.data;
            });
        },
        // 优质需求
        filterGoodNeed() {
            this.$util.post("/demand/recommendList", this.demandScreening).then((res) => {
                console.log(res);
                this.goodNeedList = res.data;
            });
        },
        // 选择省
        changeCityIndex(item, index) {
            console.log(item);

            this.provinceIndex = index;
            this.demandScreening.sendProvince = item.provinceid;
        },
        // 获取市ID
        getCityId(item, index) {
            console.log(item);

            this.show = false;
            this.cityIndex = index;
            this.demandScreening.sendCity = item.cityid;
            this.filterNeed();
        },
        // 选择分类
        changeIndex(item, index) {
            console.log(item);
            // sessionStorage.setItem("classifyId", item.id)
            // sessionStorage.setItem("clssListIndex", index)
            this.clssListIndex = index;
            this.classifyId = item.id;
            (this.demandScreening = {
                //需求筛选
                sendProvince: "", //省
                sendCity: "", //市
                day: 0, //时间
                sendIndustry: "", //行业
                userType: 0, //类型
                classifyId: "", //分类id
                pageNo: 1,
                pageSize: 24,
            }),
                (this.demandScreening.classifyId = item.id);
            this.shopSelect = {
                addr: "",
                type: 0,
                moreYear: 0,
                lessYear: 0,
                pageNo: 1,
                classifyId: "", //分类id
                pageSize: 24,
            };
            this.shopSelect.classifyId = item.id;
            this.filterNeed();
            this.getShopList();
            this.getBalnner();
            this.allNeedData();
        },
        // 筛选时间
        getTime(item) {
            this.demandScreening.day = item.day;
            this.filterNeed();
        },
        // 筛选行业
        getIndustry(item) {
            this.demandScreening.sendIndustry = item.id;
            this.filterNeed();
        },
        // 类型筛选
        getType(item) {
            this.demandScreening.userType = item.id;
            // console.log(this.demandScreening);
            this.filterNeed();
        },
        goPage() {
            this.demandScreening.pageNo = this.needNum;
            this.filterNeed();
        },
        changeNeed(num) {
            this.demandScreening.pageNo = num;
            this.filterNeed();
        },
        allShopData() {
			console.log(this.classifyId,6798787897)
            this.$util.post("/shop/indexList", {
                classifyId: this.classifyId,
            }).then((res) => {
                console.log(res);
                this.goodShopList = res.data.recommend;
            });
        },
        // 获取店铺列表
        getShopList() {
            this.$util.post("/shop/list", this.shopSelect).then((res) => {
                console.log(res);
                this.shopPage = res.pager;
                this.goodShopDefList = res.data;
            });
        },
        // 选择省
        changeShopCityIndex(item, index) {
            this.shopProIndex = index;
            // this.demandScreening.sendProvince = item.provinceid
        },
        // 获取市ID
        getShopCityId(item, index) {
            this.shopShow = false;
            this.shopCityIndex = index;
            this.shopSelect.addr = item.provinceid;
            this.getShopList();
        },
        getShopTime(item) {
            if (item.day > 0) {
                this.shopSelect.moreYear = item.day;
                this.shopSelect.lessYear = 0;
            } else {
                this.shopSelect.lessYear = 1;
                this.shopSelect.moreYear = 0;
            }
            this.getShopList();
        },
        // 类别
        shopType(item) {
            this.shopSelect.type = item.id;
            this.getShopList();
        },
        goShopPage() {
            this.shopSelect.pageNo = this.shopNum;
            this.getShopList();
        },
        changePage(num) {
            this.shopSelect.pageNo = num;
            this.getShopList();
        },
        // 跳转查看需求
        goDemand(val, id) {
            // sessionStorage.setItem("classifyId", val.classifyId)
            this.$router.push({ path: "/demand", query: { id } });
        },
        goShop(id) {
            this.$router.push({ path: "/shop", query: { id } });
        },
    },
};
</script>

<style lang="scss" scoped>
.shopName {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}
.nice-shop {
    display: flex;
    flex-wrap: wrap;
}
.goodList {
    display: flex;
    justify-content: left;
    overflow: scroll;
    overflow-y: hidden;
}
.litle-cell:hover {
    border-color: #e40012;
    background: linear-gradient(#fefafa, #fef2f2);
}
.litle-cell {
    cursor: pointer;
    margin-right: 12px;
    background: #fffafa;
    width: 245px;
    padding: 30px 20px 30px;

    margin-bottom: 20px;
    border: 1px solid #fde1e2;
    .litle-title {
        font-size: 24px;
        color: #222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .tag-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
        margin: 20px 0 24px;
        .tag {
            padding: 4px 8px;
            max-width: 60px;
            color: #222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            background: #e3e3e3;
            // margin-right: 10px;
            border-radius: 4px;
        }
    }
    .litle-money {
        font-size: 18px;
        color: #e40012;
    }
}
.classTab {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}
.tags {
    width: 1200px;
    margin: 0 auto;
    transform: translate(0, -100%);
    div {
        margin-left: 100px;
    }
    span {
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        color: #666666;
        margin-right: 20px;
    }
    .active {
        color: #e40012;
    }
}
.swiper {
    // background: lime;
    img {
        width: 100%;
        height: 100%;
    }
    width: 100%;
    height: 100%;
}
/deep/.el-carousel__button {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 100%;
    opacity: 1;
}
/deep/.el-carousel__indicator.is-active button {
    background: #e40012;
}
.mian {
    background: #f8f8f8;
    padding-bottom: 50px;
}
.mian-box {
    width: 1200px;
    background: #fff;
    margin: 0 auto;
    padding: 50px 20px 0;
}
.menus {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    div {
        text-align: center;
        font-size: 20px;
        color: #222222;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        img {
            display: block;
            margin: 0 auto 25px;
        }
    }
    .menus-active {
        color: #e40012;
    }
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    // width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 80px;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
// /deep/.el-tabs__nav-wrap::after {
//     display: none !important;
// }
.zan {
    display: flex;
    align-items: center;
    p {
        display: flex;
        justify-content: left;
    }
    font-size: 14px;
    color: #666;
    margin: 20px 0 30px;
    img {
        width: 21px;
        height: 21px;
        margin-right: 10px;
    }
}
.need {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .need-cell:hover {
        border-color: #e40012;
        background: linear-gradient(#fefafa, #fef2f2);
    }
    .need-cell {
        width: 520px;
        height: 174px;
        padding: 30px;
        border: 1px solid #fde1e2;
        margin-bottom: 20px;
        background: #fffafa;
        cursor: pointer;
        .title {
            font-size: 24px;
            color: #222;
            margin-bottom: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .tag-box {
            display: flex;
            align-items: center;
            .tag {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 4px 8px;
                max-width: 60px;
                color: #222;
                font-size: 14px;
                background: #e3e3e3;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
        .need-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #e40012;
            font-size: 24px;
            font-weight: bold;
            margin-top: 30px;
            .need-logo {
                display: flex;
                align-items: center;
                color: #222;
                font-size: 24px;
                img {
                    width: 67px;
                    height: 67px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.litle-need {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .litle-cell:hover {
        border-color: #e40012;
        background: linear-gradient(#fefafa, #fef2f2);
    }
    .litle-cell {
        cursor: pointer;
        margin-right: 12px;
        background: #fffafa;
        width: 245px;
        padding: 30px 20px 30px;

        margin-bottom: 20px;
        border: 1px solid #fde1e2;
        .litle-title {
            font-size: 24px;
            color: #222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .tag-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // flex-wrap: wrap;
            margin: 20px 0 24px;
            .tag {
                padding: 4px 8px;
                max-width: 60px;
                color: #222;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                background: #e3e3e3;
                // margin-right: 10px;
                border-radius: 4px;
            }
        }
        .litle-money {
            font-size: 18px;
            color: #e40012;
        }
    }
}
// 分类
.classification {
    width: 1200px;
    background: #fff;
    padding: 20px;
    margin: 20px auto 20px;
    display: flex;
    align-items: center;
}
.slece {
    cursor: pointer;
    margin-right: 80px;
    font-size: 16px;
    font-weight: bold;
}
.classification-content {
    width: 1200px;
    margin: 0 auto 65px;
    padding: 20px 20px 0;
    background: #fff;
}
.pagination {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.goodShop {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    overflow: scroll;
    overflow-y: hidden;
}
.shop {
    width: 280px;
    border: 1px solid #e3e3e3;
    margin-right: 8px;
    margin-bottom: 15px;
    cursor: pointer;
}
.shop:hover {
    border-color: #e40012;
    background: linear-gradient(#fefafa, #fef2f2);
}
.shop-info {
    padding: 15px;
    color: #222;
    font-size: 16px;
}
.shop-img {
    position: relative;
    height: 178px;
    // background: red;
    margin: 10px 0 20px;
    img {
        width: 100%;
        height: 100%;
    }
    div {
        width: 42px;
        color: #fff;
        height: 31px;
        line-height: 31px;
        font-size: 14px;
        text-align: center;
        background: red;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.shop-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
    div {
        padding: 7px 19px;
        background: #fde5e6;
        color: #e40012;
        margin-right: 10px;
        font-size: 12px;
        border-radius: 4px;
    }
}
.shop-num {
    padding: 20px 15px;
    background: #f8f8f8;
    color: #999999;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.areas {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #606266;
    i {
        margin-left: 4px;
    }
    .down {
        position: absolute;
        top: 45px;
        left: -20px;
        z-index: 1000;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            // background: #2ea7e0;
            color: #e40012;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .downt {
        position: absolute;
        z-index: 1000;
        top: 45px;
        left: 150px;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            color: #e40012;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .down-item:hover {
            // background: #2ea7e0;
            color: #e40012;
        }
    }
}
.down::-webkit-scrollbar {
    display: none;
}
.downt::-webkit-scrollbar {
    display: none;
}
.chat {
    padding: 10px;
    width: 143px;
    height: 49px;
    position: fixed;
    right: 0;
    background: #e40012;
    z-index: 10000;
    top: 40%;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border-radius: 4px 0px 0px 6px;
    .top {
        display: flex;
        align-items: center;
        img {
            margin-left: 20px;
            // width: 100%;
            // height: 100%;
            cursor: pointer;
        }
    }
    .bottom {
        font-size: 12px;
        color: #ffffff;
        opacity: 0.5;
    }
}
.dialogue {
    width: 400px;
    position: fixed;
    right: 40px;
    bottom: 20px;
    z-index: 10000;
    /deep/.el-collapse-item__header {
        // .el-collapse-item__header {
        background-color: #e40012;
        border-bottom: 1px solid #e40012;
        // }
    }
}
</style>
